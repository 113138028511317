import React from 'react';
import profile from '../../assets/img/profile.png'; // Adjust the path accordingly


const WebsitePages = () => {
  return (
    <div>
<div className='containermanagew'>
{/* Column 1 */}
<div className='RowManagew'>
<img src={profile} alt="profile" className='logow'/>
  <h2>HOME PAGE</h2>
  <p>This is the content of the first column.</p>
</div>

{/* Column 2 */}
<div className='RowManagew'>
<img src={profile} alt="profile" className='logow'/>
  <h2>ABOUT US</h2>
    <p>Primary Business Type</p>
</div>
<div className='RowManagew'>
<img src={profile} alt="profile" className='logow'/>
  <h2>AWARDS & MEMBERSHIPS</h2>
    <p>Primary Business Type</p>
</div>
</div>

<div className='containermanagew'>
{/* Column 1 */}
<div className='RowManagew'>
<img src={profile} alt="profile" className='logow'/>
{/* <img src='/path/to/your/logo.png' alt='Logo' className='logo' /> */}
  <h2>QUALITY & COMPLIANCE</h2>
  <p>This is the content of the first column.</p>
</div>

{/* Column 2 */}
<div className='RowManagew'>
<img src={profile} alt="profile" className='logow'/>
  <h2>INFRASTRUCTURE & FACILITIES</h2>
    <p>Primary Business Type</p>
</div>
<div className='RowManagew'>
<img src={profile} alt="profile" className='logow'/>
  <h2>TESTIMONIALS</h2>
    <p>Primary Business Type</p>
</div>
</div>


<div className='containermanagew'>
{/* Column 1 */}
<div className='RowManagew'>
<img src={profile} alt="profile" className='logow'/>
  <h2>NEWS</h2>
  <p>This is the content of the first column.</p>
</div>

{/* Column 2 */}
<div className='RowManagew'>
<img src={profile} alt="profile" className='logow'/>
  <h2>JOBS</h2>
    <p>Primary Business Type</p>
</div>
<div className='RowManagew'>
<img src={profile} alt="profile" className='logow'/>
  <h2>CUSTOM PROFILE</h2>
    <p>Primary Business Type</p>
</div>
</div>

</div>

);
};

export default WebsitePages;