import React, { useState } from 'react';
import classes from "./css/SearchBar.module.css"; 

const SearchBar = () => {
  const [query, setQuery] = useState('');

  const handleSearch = () => {
    // Implement search functionality here
    console.log('Search query:', query);
  };

  return (
    <div className="search-bar-container">
      <input
        id='Searchbar-input'
        className={classes['Searchbar-input']}
        type="text"
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <button className={classes.btn} onClick={handleSearch}>Search</button>
    </div>
  );
};

export default SearchBar;