// NewLeads.js

import React, { useState } from 'react';
import '../css/NewLeads.css';
import Formhome from '../formhome';


const NewLeads = () => {
  const [activeTab, setActiveTab] = useState('RelevantLeads');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="tabs-container">
        <button
          className={`tab-button ${activeTab === 'RelevantLeads' ? 'active' : ''}`}
          onClick={() => handleTabClick('RelevantLeads')}
        >
          Relevant Leads
        </button>
        <button
          className={`tab-button ${activeTab === 'RecentLeads' ? 'active' : ''}`}
          onClick={() => handleTabClick('RecentLeads')}
        >
          Recent Leads
        </button>
        <button
          className={`tab-button ${activeTab === 'ExportLeads' ? 'active' : ''}`}
          onClick={() => handleTabClick('ExportLeads')}
        >
          Export Leads
        </button>
        <button
          className={`tab-button ${activeTab === 'MoreLeads' ? 'active' : ''}`}
          onClick={() => handleTabClick('MoreLeads')}
        >
          More Leads
        </button>
        <button
          className={`tab-button ${activeTab === 'ShortlistedLeads' ? 'active' : ''}`}
          onClick={() => handleTabClick('ShortlistedLeads')}
        >
          Shortlisted Leads
        </button>
        <button
          className={`tab-button ${activeTab === 'LatestTenders' ? 'active' : ''}`}
          onClick={() => handleTabClick('LatestTenders')}
        >
          Latest Tenders
        </button>
        <button
          className={`tab-button ${activeTab === 'ConsumedLeads' ? 'active' : ''}`}
          onClick={() => handleTabClick('ConsumedLeads')}
        >
          Consumed Leads
        </button>
        <button
          className={`tab-button ${activeTab === 'TransactionHistory' ? 'active' : ''}`}
          onClick={() => handleTabClick('TransactionHistory')}
        >
          Transaction History
        </button>
        {/* Add more buttons for other tabs... */}
      </div>
      <div className="content-container">
        <div className={`tab-content ${activeTab === 'RelevantLeads' ? 'active' : ''}`}>
          Content for Relevant Leads
        </div>
        <div className={`tab-content ${activeTab === 'RecentLeads' ? 'active' : ''}`}>
          Content for Recent Leads
        </div>
        <div className={`tab-content ${activeTab === 'ExportLeads' ? 'active' : ''}`}>
          Content for Export Leads
        </div>
        <div className={`tab-content ${activeTab === 'MoreLeads' ? 'active' : ''}`}>
          Content for More Leads
        </div>
        <div className={`tab-content ${activeTab === 'ShortlistedLeads' ? 'active' : ''}`}>
          Content for Shortlisted Leads
          <Formhome />
        </div>
        <div className={`tab-content ${activeTab === 'LatestTenders' ? 'active' : ''}`}>
          Content for Latest Tenders
        </div>
        <div className={`tab-content ${activeTab === 'ConsumedLeads' ? 'active' : ''}`}>
          Content for Consumed Leads
        </div>
        <div className={`tab-content ${activeTab === 'TransactionHistory' ? 'active' : ''}`}>
          Content for Transaction History
        </div>
        {/* Add more content for other tabs... */}
      </div>
    </div>
  );
};

export default NewLeads;
