import React from 'react';
import './css/homepage.css';
import Formhome from './formhome';
import ImageGrid from './imagegrid'; // Adjust the path as needed

const Home = () => {
  return (
    <div className="home-page">
      {/* Left Side Content */}
      <div className="left-side">

        {/* Three-row text section */}
        <div className="three-row-text">
          <div className="row">
            <h2>Grow your Business</h2>
            <p>No commission or transaction fees</p>
          </div>
          <div className="row">
            <h2>Zero Cost</h2>
            <p>Lead Management System & other features</p>
          </div>
          <div className="row">
            <h2>Manage Business</h2>
            <p>Lead Management System & other features</p>
          </div>
          

            {/* Right Side Content */}
            <div className="right-side">
              <Formhome />
            </div>
              </div>

      
        {/* Image Grid Component */}
        <ImageGrid />
      </div>
    </div>
  );
};

export default Home;
