// admin/AdminDashboard.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './Sidebar';
import RegistrationForm from '../admin/AdminRegistration';
import '../css/dashboard.css';
import AddProduct from './product/AddProduct';
import ManageLeads from '../admin/ManageLeads';
import ProductDashboard from './productdashboard';
import ProductPage from './product/AddProductDetails';
import NewLeads from './Newleads';
import Settings from './Setting';
import DashboardPopup from '../popups/DashboardPopup';
import Profile from '../admin/Profile';
import Register from '../admin/profile/Register';
import Dashboard from '../admin/Dashboard';
import PageViews from '../admin/PageView';
import MediaUploadForm from '../admin/Uploads';



const AdminDashboard = () => {
  return (
    <div>
      <div className="admin-container">
        <Sidebar />
        <div className="admin-content">
          <Routes>
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/profile/Register" element={<Register />} />
          <Route path="/AdminRegistration" element={<RegistrationForm />} />
          <Route path="/popups/DashboardPopup" element={<DashboardPopup />} />
          <Route path="/AddProduct" element={<AddProduct />} />
          <Route path="/ManageLeads" element={<ManageLeads />} />
          <Route path="/productdashboard" element={<ProductDashboard />} />
          <Route path="/AddProductDetails" element={<ProductPage />} />
          <Route path="/PageView" element={<PageViews />} />
          <Route path="/NewLeads" element={<NewLeads />} />
          <Route path="/Setting" element={<Settings />} />
          <Route path="/Uploads" element={<MediaUploadForm />} />
          </Routes>
        </div>
       
      </div>
    </div>
  );
};

export default AdminDashboard;
