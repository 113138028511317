// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminDashboard from './admin/AdminDashboard';
import AddProduct from './admin/product/AddProduct';
import Header from './Header';
import Home from './Home';
import Footer from './Footer';

class App extends React.Component {
  // ... (no changes to the constructor and state)

  render() {
    return (
      <Router>
        {/* Global Header component */}
        <Header />
        <Routes>
          {/* Default route for the root path */}
          <Route index element={<Home />} />
          {/* Admin routes */}
          
          <Route path="/admin/*" element={<AdminDashboard />} />
          <Route path="/admin/add-product" element={<AddProduct />} />
        </Routes>
        {/* Global Footer component */}
        <Footer />
      </Router>
    );
  }
}

export default App;
