import React, { useState } from 'react';
import '../css/adminDashboard.css'; // Import your CSS file
import AddProduct from './product/AddProduct';
import AddProductDetails from './product/AddProductDetails';
import ActiveProduct from './product/ActiveProduct';
import InActiveProduct from './product/InActiveProduct';

const ProductDashboard = () => {
  const [selectedForm, setSelectedForm] = useState(null);


  const handleFormButtonClick = (form) => {
    setSelectedForm(form);
  };

  return (
    <div className="admin-dashboard-container">
      <header className="admin-header">
        <h1>Product Dashboard</h1>
        <div className="search-bar">
          <input type="text" placeholder="Search..." />
          <button>Search</button>
        </div>
      </header>

      <div className="add-buttons">
        <button onClick={() => handleFormButtonClick('AddProductDetails')} className="regular-add">
          Add Product
        </button>
        <button onClick={() => handleFormButtonClick('AddProduct')} className="quick-add">
          Quick Add
        </button>
      </div>
      
      <div className="tab-buttons">
        <button onClick={() => handleFormButtonClick('ActiveTab')} className="regular-add">
          Active Product
        </button>
        <button onClick={() => handleFormButtonClick('InActiveTab')} className="quick-add">
          Inactive product
        </button>
      </div>

      {/* {/* <div className="tab-buttons">
        <button onClick={() => handleTabChange(1)} className={activeTab === 1 ? 'active' : ''}>
          Active Tab
        </button>
        <button onClick={() => handleTabChange(2)} className={activeTab === 2 ? 'active' : ''}>
          Inactive Tab
        </button> 
        
      </div> */}

      {/* Display selected form */}
      {selectedForm === 'AddProduct' && <AddProduct />}
      {selectedForm === 'AddProductDetails' && <AddProductDetails />}
      {selectedForm === 'ActiveTab' && <ActiveProduct />}
      {selectedForm === 'InActiveTab' && <InActiveProduct />}

      {/* Rest of the content based on the active tab */}
     
    </div>
  );
};

export default ProductDashboard;
