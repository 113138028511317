import profile from '../assets/img/profile.png';
import dashboard from '../assets/img/dashboard.png';
import BuyLeads from '../assets/img/analysis.png';
import product from '../assets/img/product.png';
import catalogue from '../assets/img/catalogue.png';
import upload from '../assets/img/upload.png';
import setting from '../assets/img/setting.png';
import Performance from '../assets/img/analysis.png';
import leadmanager from '../assets/img/manager.png';
import Invoices from '../assets/img/invoice.png';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Sidebar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div id="sidebar" className='sidebar'>
      <img src={dashboard} alt="dashboard" className='logoi'/>
      <div className="dropdown">
        <Link to="../admin/Dashboard" className="menu-item" onClick={toggleDropdown}>
        Dashboard
        </Link> 
        {dropdownOpen && (
          <div className="dropdown-content">
            <Link to="/admin/AdminRegistration">Admin Registration</Link>
            <Link to="/admin/Profile">Messages</Link>
            <Link to="/AddProduct">Catalogue views</Link>
            <Link to="/AddProduct">Relevant BuyLeads</Link>
            {/* Add other dropdown items as needed */}
          </div>
        )}
       </div><br></br>

       <div className="dropdown">
      <img src={profile} alt="profile" className='logoi'/>
      <Link to="/admin/Profile" onClick={toggleDropdown} className="menu-item">
        Profile
      </Link>
      {dropdownOpen && (
          <div className="dropdown-content">
            <Link to="/admin/Profile/Register">Primary Details</Link>
            <Link to="/admin/Profile/AdditionalDetails">Additional Details</Link>
            <Link to="/admin/Profile/Websitepages">Website Pages</Link>
            <Link to="/AddProduct">Share Catelog </Link>
            {/* Add other dropdown items as needed */}
          </div>
        )}
      </div>


      <img src={product} alt="product" className='logoi'/>
      <Link to="/admin/productdashboard" className="menu-item">
        Products
      </Link>
      <img src={leadmanager} alt="leadmanager" className='logoi'/>
      <a className="menu-item" href="/admin/ManageLeads">
         Manage Leads
      </a>
      <div className="dropdown">
      <img src={BuyLeads} alt="BuyLeads" className='logoi'/>
      <Link to="/admin/Newleads" onClick={toggleDropdown} className="menu-item">
      New Leads
      </Link>
      {dropdownOpen && (
          <div className="dropdown-content">
            <Link to="/AdminRegistration">Relevant Leads</Link>
            <Link to="/AddProduct">Recent Leads</Link>
            <Link to="/AddProduct">Export Leads</Link>
            <Link to="/AddProduct">More Leads</Link>
            <Link to="/AddProduct">Shortlisted Leads</Link>
            <Link to="/AddProduct">Latest Tenders</Link>
            <Link to="/AddProduct">Consumed Leads</Link>
            <Link to="/AddProduct">Transaction History</Link>
            {/* Add other dropdown items as needed */}
          </div>
        )}
      </div>



      <img src={catalogue} alt="catalogue" className='logoi'/>
      <a className="menu-item" href="/admin/PageView">
        Page View
      </a>
      <img src={upload} alt="upload" className='logoi'/>
      <a className="menu-item" href="/admin/Uploads">
        Photos & docs
      </a>
      <img src={Performance} alt="Performance" className='logoi'/>
      <a className="menu-item" href="/">     
           Performance Reports
      </a>
      <img src={setting} alt="setting" className='logoi'/>
      <a className="menu-item" href="/admin/Setting">
        Settings
      </a>
      <img src={Invoices} alt="Invoices" className='logoi'/>
      <a className="menu-item" href="/desserts">
      Invoices
      </a>
    </div>

  );
};

export default Sidebar;
