//ContactForm.js
import React from 'react';
import '../../css/contactform.css';

const InActiveProduct = () => {
  



  return (
    <div>
      <h1>InActive Product</h1><p>“Optimization” that drives you to page 1 ranking! Google is getting smarter day by day & now it’s your website’s turn. Give the needed stimulus to your online marketing efforts through SEO & become Google’s favorite.

Increase the response rate of your mass-mailing through the optimized E-mail Marketing! Here, at weblinkIndia.net, our proven e-mail marketing optimization increases the click-through rates and lowers the spam reporting by improved targeting and strategized mailing.

Online Reputation Management
Brands we build, mend, and monitor, attain a spotless & prominent web stature! Our team uses effective tools that monitor your image online and try to push down every negative review on google that weigh down your brand with work strategies that guarantee immediate results.

Conversion Rate Optimization
From evaluating the barriers that are impeding conversions on your page to removing distractions that are the possible cause of increasing bounce rate, CRO services focus on optimizing your website for high conversion by offering visitors what exactly they are looking for.</p>
      
    </div>
  );
};

export default InActiveProduct;
