// Inside DashboardPopup.js
import React from 'react';

const DashboardPopup = () => {
  // Component logic here
  

  return (
    <div className="dashboard-popup">
      <h2>Dashboard Popup</h2>
      <h2>Register</h2>
      <h2>sign in</h2>
      {/* Add your content and styling here */}
  

    </div>
  );
};

export default DashboardPopup;
