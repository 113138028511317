import React, { useState, useEffect } from 'react';
import '../css/manageleads.css';

const ManageLeads = () => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  // Fetch data in your React component
  
  const fetchData = async () => {
    try {
      const response = await fetch('/getContactFormData');
      const contentType = response.headers.get('content-type');
  
      console.log('Content-Type:', contentType);
  
      const data = await response.json();
      console.log('Fetched data:', data);
      setContacts(data); // Update state with fetched data
      // Update state or perform other actions with the fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
 
  

  return (
    <div className='containermanage'>
      {/* Display the contacts in the first column */}
      <div className='RowManage'>
        <h2>All Contacts</h2>
        <ul>
          {contacts.map((contact) => (
            <li key={contact.id}>
              <strong>Name:</strong> {contact.name}, <strong>Email:</strong> {contact.email}, <strong>Mobile:</strong> {contact.mobile}, <strong>Message:</strong> {contact.message}
            </li>
          ))}
        </ul>
      </div>

      {/* Column 2 */}
      <div className='RowManage'>
        <h2>Live Updates</h2>
        <p>This is the content of the second column.</p>
      </div>
    </div>
  );
};

export default ManageLeads;
