import React, { useState } from 'react';
import '../css/upload.css'; 



const MediaUploadForm = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('media', selectedFile);

    try {
      const response = await fetch('http://localhost:3001/upload', {
      method: 'POST',
      body: formData,
      });

      if (response.ok) {
        console.log('Media uploaded successfully');
        // Handle success, e.g., show a success message or update UI
      } else {
        console.error('Media upload failed');
        // Handle failure, e.g., show an error message
      }
    } catch (error) {
      console.error('Error uploading media:', error);
    }
  };

  return (
    <form className="Uform" onSubmit={handleSubmit}>
      <input type="file" onChange={handleFileChange} />
      <button type="submit">Upload</button>
    </form>
  );
};

export default MediaUploadForm;
