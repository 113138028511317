
import React from 'react';
import '../css/manageleads.css';

const Dashboard = () => {
  return (
         <div>
            <div className='containermanage'>
            {/* Column 1 */}
            <div className='RowManageD'>
            <h2>365 MESSAGES</h2>
            <p>This is the content of the first column.</p>
            </div>

            {/* Column 2 */}
            <div className='RowManageD'>
            <h2>200 RELEVANT BUYLEADS</h2>
            <p>This is the content of the second column.</p>
            </div>
            {/* Column 3 */}
            <div className='RowManageD'>
            <h2>99+ CATALOG VIEWS</h2>
            <p>This is the content of the second column.</p>
            </div>
            </div>


            
                  <div className='containermanage'>
                      <div className='RowManageD'>
                        <h2>Average Daily Bills</h2>
                        <p>This is the content of the second column.</p>
                      </div>
                   <div className='RowManageD'>
                    <h2>Invoice</h2>
                    <p>This is the content of the second column.</p>
                   </div>
                  </div>

                  <div className='containermanage'>
                          <div className='RowManageD'>
                            <h2>Project Status</h2>
                            <p>This is the content of the second column.</p>
                          </div>
                        <div className='RowManageD'>
                          <h2>Todo List</h2>
                          <p>This is the content of the second column.</p>
                        </div>
                        <div className='RowManageD'>
                          <h2>Documents</h2>
                          <p>This is the content of the second column.</p>
                        </div>
                  </div>
         </div>
                
  );
};
export default Dashboard;