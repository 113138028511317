// App.js

import React, { useState } from 'react';
import '../../css/productdetails.css'; // Import your CSS file
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles
const AddProducts = () => {
  const [productDescription, setProductDescription] = useState('');
  const [activeTab, setActiveTab] = useState(1);

  const handleDescriptionChange = (value) => {
    setProductDescription(value);
  };

  const [productData, setProductData] = useState({
    productName: '',
    category: '',
    price: 0,
    stockQuantity: 0,
    images: [],
    sku: '',
    brand: '',
    shippingInfo: '',
    attributes: '',
    Tags: '',
    availabilityStatus: true,
    relatedProducts: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const newImages = Array.from(files).map((file) => URL.createObjectURL(file));

    setProductData((prevData) => ({
      ...prevData,
      images: [...prevData.images, ...newImages],
    }));
  };
  

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const productDescriptionJSON = JSON.stringify(productDescription);
  //   console.log('Product data submitted:', { ...productData, productDescription: productDescriptionJSON });
  //   // Add logic to send productData to the server or perform other actions
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!areRequiredFieldsFilled()) {
      // Add validation logic or display an error message
      return;
    }
  
    try {
      const response = await fetch('/api/products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...productData,
          productDescription: JSON.stringify(productDescription),
        }),
      });
  
      if (response.ok) {
        console.log('Product data submitted successfully');
        // Add logic to handle success (e.g., redirect to a success page)
      } else {
        console.error('Error submitting product data:', response.statusText);
        // Add logic to handle errors (e.g., display an error message)
      }
    } catch (error) {
      console.error('Error submitting product data:', error.message);
      // Add logic to handle network errors
    }
  };

  const handleTabChange = (tab) => {
    if (tab === 1) {
      setActiveTab(1);
    } else if (tab === 2) {
      setActiveTab(2);
    }
  };

  const areRequiredFieldsFilled = () => {
    return (
      productData.productName &&
      productDescription &&
      productData.category &&
      productData.images.length > 0 &&
      productData.price
    );
  };



  return (
    <div>
     <div className="add-product-container">
        <form className="Productdetailform" onSubmit={handleSubmit}>
          <div className="tab-buttons">
            <button onClick={() => handleTabChange(1)} className={activeTab === 1 ? 'active' : ''}>
              Product Basic Details
            </button>
            <button
              onClick={() => handleTabChange(2)}
              className={activeTab === 2 ? 'active' : ''}
              disabled={!areRequiredFieldsFilled()}
            >
              Additional Details
            </button>
          </div>
        
        {/* ... other fields ... */}

        {activeTab === 1 && (
         <div>
         <label>
           Product Name:
           <input
             type="text"
             name="productName"
             required
             className="Productdetailinput"
             value={productData.productName}
             onChange={handleInputChange}
           />
         </label>

         <label>
           Product Description:
           <ReactQuill
             className="Productdiscription"
             value={productDescription}
             onChange={handleDescriptionChange} 
           />
         </label>
         
            <label>
              Category : &nbsp;
              <input
                type="text"
                name="category"
                required
                className="rowinput"
                value={productData.category}
                onChange={handleInputChange}
              />&nbsp; &nbsp;
              
            Price :&nbsp;
              <input
                type="text"
                name="price"
                className="rowinput"
                value={productData.price}
                onChange={handleInputChange}
              />
   <br></br>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;
              Images: &nbsp; &nbsp;
              <input
                type="file"
                name="images"
                className="imageinput"
                onChange={handleImageChange}
                multiple
              />
            </label>
            <div className="image-preview-container">
              {productData.images.map((image, index) => (
                <img key={index} src={image} alt={`Preview ${index}`} className="image-preview" />
              ))}
            </div>
          </div>
        )}


        
           <div>
            <button
              onClick={() => handleTabChange(2)}
              className={activeTab === 2 ? 'active' : ''}
              disabled={!areRequiredFieldsFilled()}
            >
              Additional Details
            </button>
          </div>
          
          <br></br>

          
        {activeTab === 2 && (
          <div>
            {/* Additional details fields */}
            <label>
              Stock Quantity : &nbsp; &nbsp;
              <input
                type="text"
                name="stockQuantity"
                className="rowinput"
                value={productData.stockQuantity}
                onChange={handleInputChange}
              />
              &nbsp; &nbsp; Sku : &nbsp;

              <input type="text" name="sku" className='rowinput' value={productData.sku} onChange={handleInputChange} />
            </label>
            <label>
        Brand :&nbsp;
          <input type="text" name="brand" className='rowinput' value={productData.brand} onChange={handleInputChange} />
      <space>    </space>
        ShippingInfo : &nbsp;
          <input type="text" name="shippingInfo" className='rowinput' value={productData.shippingInfo} onChange={handleInputChange} />
        </label>
        <label>
        Attributes : &nbsp;
          <input  type="text" name="attributes" className='rowinput' value={productData.attributes} onChange={handleInputChange} />
        <space> </space> AvailabilityStatus : &nbsp; &nbsp;
          <input type="text" name="availabilityStatus" className='rowinput' value={productData.availabilityStatus} onChange={handleInputChange} />
        </label>
        <label >
        Tags : &nbsp;
          <input type="text" name="Tags" className='Productdetailinput' value={productData.Tags} onChange={handleInputChange} />
        </label>
        <label>
        &nbsp; &nbsp;Related Products : &nbsp;
          <input type="text" name="relatedProducts" className='Productdetailinput' value={productData.relatedProducts} onChange={handleInputChange} />
        </label>
        {/* Repeat the above pattern for other fields */}

            {/* Add other additional details fields as needed */}
          </div>
        )}

        <button type="submit">Add Product</button>

        
      </form>
 
    </div>
          
    {/* <div className="right-side">
        <h1>Product Example</h1>
      </div> */}
    </div>
    
  );
};


export default AddProducts;
