import React from 'react';
import  './css/header.css';
import {Link} from  'react-router-dom';
import logoo from  './assets/img/logo-s.jpg';
import SearchBar from './Searchbar';


function Header() {
  return (
    <div className='kjd'>
      <div className='container'>
        <div className='Logoo'>
          <img src={logoo} alt='logoo' className='img1' />
        </div>
        <div className='search'>
          {/* Your search component goes here */}
          <SearchBar />
        </div>
        <div className='blinklistl'>
          <ul className='blinklist'>
            <li className='lit'><Link to='/'>Shopping</Link></li>
            <li className='li'><Link to='/ManageLeads'>ManageLeads</Link></li>
            <li className='li'><Link to='/Buyleads'>BuyLeads</Link></li>
            <li className='li'><Link to='/Products'>Products</Link></li>
            <li className='li'><Link to='/Sell'>Sell</Link></li>
            <div className='dropdown'>
            <li className='li'><Link to='/Help'>Help</Link></li>
              <div className='dropdownContent'>
                <a href='/Home'>Home</a>
                <a href='/PostYourRequirement'>Post Your Requirement</a>
                <a href='/VerifiedBussinessBuyer'>Verified Bussiness Buyer</a>
                <a href='/ProductsDirectory'>Products Directory</a>
                <a href='/Myorders'>My Orders</a>
                <a href='/Recentactivity'>Recent Activity</a>
                <a href='/Settings'>Settings</a>
                <a href='/Paywithsihauli'>Pay With Sihauli</a>
                <a href='/Shipwithsihauli'>Ship With Sihauli</a>
                <a href='/Bussinessloan'>Bussiness Loan</a>
               </div>
               </div>
            <div className='dropdown'>
              <li className='li'><Link to='/forms/ContactFrom'>Sign</Link></li>
              <div className='dropdownContent'>
                <a href='/Home'>Home</a>
                <a href='/PostYourRequirement'>Post Your Requirement</a>
                <a href='/VerifiedBussinessBuyer'>Verified Bussiness Buyer</a>
                <a href='/ProductsDirectory'>Products Directory</a>
                <a href='/Myorders'>My Orders</a>
                <a href='/Recentactivity'>Recent Activity</a>
                <a href='/Settings'>Settings</a>
                <a href='/Paywithsihauli'>Pay With Sihauli</a>
                <a href='/Shipwithsihauli'>Ship With Sihauli</a>
                <a href='/Bussinessloan'>Bussiness Loan</a>
              </div>
            </div>
          </ul>
        </div>
      </div>

    </div>
  );
}

export default Header;
