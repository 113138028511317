import React from 'react';

const CatalogView = () => {
  return (
    <div style={{ display: 'flex' }}>
      {/* Left Column */}
      <div style={{ flex: 1, padding: '20px', backgroundColor: '#f0f0f0' }}>
        <h2>Catalog View</h2>
        <p>This is the content of the left column.</p>
      </div>

      {/* Right Column */}
      <div style={{ flex: 1, padding: '20px', backgroundColor: '#e0e0e0' }}>
        <h2>Catalog Download</h2>
        <p>This is the content of the right column.</p>
      </div>
    </div>
  );
};

export default CatalogView;
