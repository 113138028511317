import React, { useState } from 'react';
import Register from './profile/Register';
import FormPop from './popup';
import '../css/profile.css';
import AdditionalDetails from './profile/AdditionalDetails';
import WebsitePages from './profile/Websitepages';

const Profile = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);

  const openForm = (formType) => {
    setSelectedForm(formType);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleFormButtonClick = (form) => {
    setSelectedForm(form);
  };

  const renderSelectedForm = () => {
    switch (selectedForm) {
      case 'registration':
        return <Register />;
      case 'contact':
        return <FormPop />;
      default:
        return null;
    }
  };

  return (
    <div>
      <button onClick={() => handleFormButtonClick('RegisterTab')}>Primary Details</button> &nbsp; &nbsp;
      <button onClick={() => openForm('ActiveTab')}>Performance Report</button> &nbsp; &nbsp;
      <button onClick={() => handleFormButtonClick('AdditionalDetailsTab')}>Additional Details</button> &nbsp; &nbsp;
      <button onClick={() => handleFormButtonClick('ActiveTab')}>Rating & Reviews</button> &nbsp; &nbsp;
      <button onClick={() => handleFormButtonClick('WebsiteTab')} >Website Pages</button>

      {/* Popup */}
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <button onClick={closePopup}>Close</button>
            {renderSelectedForm()}
          </div>
        </div>
      )}




{selectedForm === 'AdditionalDetailsTab' && <AdditionalDetails />}
{selectedForm === 'RegisterTab' && <Register />}
{selectedForm === 'WebsiteTab' && <WebsitePages />}

    </div>
  );
};

export default Profile;
