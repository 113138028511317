import React from 'react';

const AdditionalDetails = () => {
  return (
<div className='containermanage'>
    
{/* Column 1 */}
<div className='RowManage'>
  <h2>Additional Business Details</h2>
  <p>This is the content of the Additional Business Details</p>
</div>

{/* Column 2 */}
<div className='RowManage'>
  <h2>Additional Contact Information</h2>
  <div className='RowManage'> 
    <p>Primary Business Type</p>
    <p>Manufacturer</p>
  </div>
  

  <div className='manageBcolumn'>
  <h2>Bank Details</h2>
  <p>This is the content of the second column.</p>
</div>
</div>
</div>
);
};

export default AdditionalDetails;