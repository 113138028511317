import React, { useState } from 'react';
import '../../css/AddProduct.css';

const AddProduct = () => {
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productImage, setProductImage] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);

  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
  };

  const handleProductPriceChange = (e) => {
    setProductPrice(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProductImage(file);
  };

  const handleCheckboxChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!termsChecked) {
      alert('Please agree to the terms before submitting.');
      return;
    }

    const formData = new FormData();
    formData.append('productName', productName);
    formData.append('productPrice', productPrice);
    formData.append('productImage', productImage);

    try {
      const response = await fetch('http://localhost:3001/products', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log('Product added successfully');
        // Optionally, you can handle success (e.g., redirect to another page)
      } else {
        console.error('Failed to add product');
      }
    } catch (error) {
      console.error('Error:', error);
    }

    setProductName('');
    setProductPrice('');
    setProductImage(null);
    setTermsChecked(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="productName">Product Name:</label>
        <input
          type="text"
          id="productName"
          value={productName}
          onChange={handleProductNameChange}
          required
        />

        <br />

        <label htmlFor="productPrice">Product Price:</label>
        <input
          type="text"
          id="productPrice"
          value={productPrice}
          onChange={handleProductPriceChange}
          required
        />

        <br />

        <label htmlFor="productImage">Product Image:</label>
        <input
          type="file"
          id="productImage"
          accept="image/*"
          onChange={handleImageChange}
          required
        />

        <br />

        <label className="checkbox-label">
          <input
            type="checkbox"
            checked={termsChecked}
            onChange={handleCheckboxChange}
            required
          />
          I agree to the terms and conditions
        </label>

        <br />

        <button type="submit">Add Product</button>
      </form>
    </div>
  );
};

export default AddProduct;
