import './css/homeform.css';
// Import necessary modules
import React, { useState } from 'react';
import ContactForm from './forms/ContactForm';

// Your existing Formhome component
const Formhome = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isFormVisible, setFormVisible] = useState(false);
  const handleTextClick = () => {
    setFormVisible(true);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:3001/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Redirect to the admin page after successful login
        // You might want to handle this based on the user's role or additional checks
        window.location.href = '/admin';
      } else {
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div>
      <form onSubmit={handleLogin}>
        <label htmlFor="name">User Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />

        <br />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <br />
        <input type="submit" value="Login" />
      
      </form>
      {isFormVisible ? (
          <ContactForm />
        ) : (
          <p onClick={handleTextClick}>Click to Register Here</p>
        )}
    </div>
  );
};

export default Formhome;
