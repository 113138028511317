// ImageGrid.js
import React from 'react';
import product1 from './assets/img/practie.webp';
import product from './assets/img/iaf.jpg';
import food from './assets/img/food.jpg';
const ImageGrid = () => {
  return (
    <div className="image-grid">
      {/* Your image components go here */}
      <img src={product} alt="product"  className="product" />
      <img src={product1} alt="product1" className="product1" />
      <img src={food} alt="food"  className="product2" />
      {/* Add more images as needed */}
    </div>
  );
};

export default ImageGrid;
