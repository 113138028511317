import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests
import '../../css/activeproduct.css'
const ActiveProduct = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch products when the component mounts
    fetchProducts();
  }, []);
  

  const fetchProducts = async () => {
    try {
      const response = await axios.get('http://localhost:3001/getproduct');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  return (
    <div className="product-grid">
      {products.map((product) => (
        <div key={product._id} className="product-item">
          <img src={product.productImage} className='active-image' alt={product.productName} />
          <h3>{product.productName}</h3>
          <p>Price: {product.productPrice}</p>
        </div>
      ))}
    </div>

  );
};

export default ActiveProduct;
